import React from 'react';
import {Row, Col} from 'antd'
import "antd/dist/antd.css";
import styled from "styled-components";

const LogoImage = styled.a`
    background-image: url("https://withapp-prod.s3.ap-northeast-2.amazonaws.com/nftHomePageBanner/HomePageBanner1.png");
    height: 60px;
    width: 60px;
    background-size: contain;
    background-repeat: no-repeat;
`

const FLFImage = styled.a`
    background-image: url("https://withapp-prod.s3.ap-northeast-2.amazonaws.com/nftHomePageBanner/HomePageBanner1.png");
    height: 60px;
    width: 150px;
    background-size: contain;
    background-repeat: no-repeat;
`

const FooterUpper = () => {
    return (
        <div>
         <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
                <LogoImage href={"www.projectwith.io"}/>
            </Col>
            <Col xs={24} md={12}>
                <FLFImage href={"https://website-26313.convertflowpages.com/page-88805"}/>
            </Col>
        </Row>
        </div>
    );
};

export default FooterUpper;