import './App.css';
import DashBoard from "./dashBoard";
import LiveClock from "./liveClock";
import Banner from "./banner";
import Footer from "./footer";
import styled from "styled-components";
import FooterUpper from "./footerUpper";

const MarginComponent = styled.div`
  width: 10px;
  height: 50px;
`


function App() {
  return (
    <div className="App">
        <Banner/>
        <MarginComponent/>
        <LiveClock/>
        <DashBoard/>
        <FooterUpper/>
        <Footer/>
    </div>
  );
}

export default App;
