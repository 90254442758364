import React from 'react';
import Clock from 'react-live-clock'
import ReactTextFit from 'react-textfit'
import "antd/dist/antd.css";
import {Col, Row} from "antd";

const LiveClock = () => {
    return (
            <Row>
                <Col xs={4} md={6}/>
                <Col xs={16} md={12}>
                    <ReactTextFit mode={"single"} style={{fontFamily: "Arial Black"}}>
                        <Clock
                            format="YYYY/MM/DD HH:mm:ss"
                            interval={1000}
                            ticking={true}
                            timezone={'Asia/Seoul'}
                        />
                    </ReactTextFit>
                </Col>
                <Col xs={4} md={6}/>
            </Row>
    );
};

export default LiveClock;