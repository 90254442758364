import React from 'react';
import styled from "styled-components";

const BannerStyle = styled.div`
    background-color: grey;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-top: 15px;
    padding-left: 15px;
`

const LogoImage = styled.a`
    background-image: url("https://withapp-prod.s3.ap-northeast-2.amazonaws.com/homePage/WITH_LOGO_WHITE.png");
    height: 60px;
    width: 60px;
    background-size: contain;
    background-repeat: no-repeat;
`

const Banner = () => {
    return (
        <BannerStyle>
            <LogoImage href={"http://circulating.projectwith.io"}/>
        </BannerStyle>
    );
};

export default Banner;