import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Row, Button} from "antd";
import "antd/dist/antd.css";
import CoinGecko from "coingecko-api"

const CoinGeckoClient = new CoinGecko();

const TitleBig = styled.div`
  font-family: "Arial Black";
  font-size: 30px;
  font-weight: bold;
`;

const TitleSmall = styled.div`
  font-family: "Arial Black";
  font-size: 25px;
  font-weight: bold;
`;

const BigContents = styled.div`
  font-family: "Noto Sans";
  font-size: 25px;
`;

const MediumContents = styled.div`
  font-family: "Noto Sans";
  font-size: 20px;
`;

const SmallMediumContents = styled.div`
  font-family: "Noto Sans";
  font-size: 15px;
`;

const SmallContents = styled.div`
  font-family: "Noto Sans";
  font-size: 20px;
`;

const BigBracket = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Noto Sans";
  font-size: 20px;
  color: dimgrey;
`;

const SmallBarcket = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Noto Sans";
  font-size: 15px;
  color: dimgrey;
`;

const MarginComponent = styled.div`
  width: 10px;
  height: 50px;
`

const MarginComponentLarge = styled.div`
  width: 10px;
  height: 100px;
`

const DashBoard = () => {

    const [symbol, setSymbol] = useState("WIKEN");
    const [price, setPrice] = useState(0);
    const [krwPrice, setKrwPrice] = useState(0);
    const [totalCurrency, setTotalCurrency] = useState(1034624678);
    const [totalSupply, setTotalSupply] = useState(1057786429);
    const [totalStake, setTotalStake] = useState(totalSupply-totalCurrency);
    const [tradeVolume, setTradeVolume] = useState();
    const [krwTradeVolume, setKrwTradeVolume] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await CoinGeckoClient.coins.fetch('project-with', {});
                const resultPrice = await CoinGeckoClient.simple.price({
                    ids: ['project-with'],
                    vs_currencies: ['usd', 'krw'],
                    include_24hr_vol: true,
                    include_24hr_change: true
                });
                setSymbol(result.data.symbol);
                setPrice(resultPrice.data['project-with']['usd']);
                setKrwPrice(resultPrice.data['project-with']['krw']);
                setTradeVolume(resultPrice.data['project-with']['usd_24h_vol'])
                setKrwTradeVolume(resultPrice.data['project-with']['krw_24h_vol'])
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    return (
        <div>

            <Row gutter={[16, 16]}>

                <Col span={24}>
                    <MarginComponent/>
                </Col>

                <Col xs={24} md={12} xl={12}>
                    <TitleBig>WIKEN Price</TitleBig>
                    <BigContents>{krwPrice} KRW</BigContents>
                    <MediumContents>( {price} USD )</MediumContents>
                </Col>
                <Col xs={24} md={12} xl={12}>
                    <TitleBig>Market Cap</TitleBig>
                    <BigBracket>( Total Tradable WIKEN )</BigBracket>
                    <BigContents>{(Math.round(krwPrice*totalCurrency)).toLocaleString()} KRW</BigContents>
                    <MediumContents>( {(Math.round(price*totalCurrency)).toLocaleString()} USD )</MediumContents>
                    <MediumContents>( {(Math.round(totalCurrency)).toLocaleString()} WIKEN )</MediumContents>
                    <BigBracket>( {Math.round(totalCurrency/totalSupply*100)}% of Total Supply WIKEN )</BigBracket>
                </Col>

                <Col span={24}>
                    <MarginComponent/>
                </Col>

                <Col xs={24} md={8} xl={8}>
                    <TitleSmall>Trading Volume</TitleSmall>
                    <SmallContents>{(Math.round(krwTradeVolume)).toLocaleString()} KRW</SmallContents>
                    <SmallMediumContents>( {(Math.round(tradeVolume)).toLocaleString()} USD )</SmallMediumContents>
                </Col>
                <Col xs={24} md={8} xl={8}>
                    <TitleSmall>Total Staked WIKEN</TitleSmall>
                    <SmallContents>{(totalStake).toLocaleString()} WIKEN</SmallContents>
                    <SmallBarcket>( {(Math.round(totalStake/totalSupply*100))}% of Total Supply WIKEN )</SmallBarcket>
                </Col>
                <Col xs={24} md={8} xl={8}>
                    <TitleSmall>Market Cap</TitleSmall>
                    <SmallBarcket>( Total Supply WIKEN )</SmallBarcket>
                    <SmallContents>{(Math.round(krwPrice*totalSupply)).toLocaleString()} KRW</SmallContents>
                    <SmallMediumContents>( {(Math.round(price*totalSupply)).toLocaleString()} USD )</SmallMediumContents>
                    <SmallBarcket>( {(Math.round(totalSupply)).toLocaleString()} WIKEN )</SmallBarcket>
                </Col>

            </Row>

            <MarginComponentLarge/>

        </div>
    );
};

export default DashBoard;