import React from 'react';
import styled from "styled-components";
import {Col, Row, Button} from "antd";
import "antd/dist/antd.css";
import {InstagramOutlined, FacebookOutlined, TwitterOutlined, MediumOutlined} from "@ant-design/icons"

const FooterStyle = styled.div`
  background-color: grey;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  padding-right: 15px;
  position: fixed;
  left: 0;
  bottom: 0;
`

const FooterTextStyle = styled.div`
  padding-top: 13px;
  font-family: "Noto Sans";
  font-size: 12px;
  color: whitesmoke;
  position: fixed;
  left: 15px;
  bottom: 25px;
`

const Footer = () => {
    return (
        <FooterStyle>
            <FooterTextStyle>
                © ProjectWITH
            </FooterTextStyle>
            <Button
                icon={<MediumOutlined/>}
                style={{
                    background: "white",
                    width: 50,
                    height: 50,
                    marginRight: 10,
                    borderColor: "white"
                }}
                onClick={(event) => window.location.href = "https://projectwith-official.medium.com/"}
            />
            <Button
                icon={<InstagramOutlined/>}
                style={{
                    background: "white",
                    width: 50,
                    height: 50,
                    marginRight: 10,
                    borderColor: "white"
                }}
                onClick={(event) => window.location.href = "https://www.instagram.com/projectwith_official/"}
            />
            <Button
                icon={<FacebookOutlined/>}
                style={{
                    background: "white",
                    width: 50,
                    height: 50,
                    marginRight: 10,
                    borderColor: "white"
                }}
                onClick={(event) => window.location.href = "https://www.facebook.com/pg/ProjectWITH/posts/"}
            />
            <Button
                icon={<TwitterOutlined/>}
                style={{
                    background: "white",
                    width: 50,
                    height: 50,
                    borderColor: "white"
                }}
                onClick={(event) => window.location.href = "https://twitter.com/project_with_"}
            />
        </FooterStyle>
    );
};

export default Footer;